import { vars } from '../helpers';
import * as convertors from '../convertors';

export const getGroupsCssVars = vars(
  ({ styleParams: { numbers, booleans } }) => ({
    'header-align': convertors.textAlign(numbers.headerAlignment),
    'feed-align': convertors.justifyContent(numbers.feedAlignment),
    'feed-title-display': convertors.display(booleans.showFeedTitle),
    'feed-subtitle-display': convertors.display(booleans.showFeedSubtitle),
    'header-title-display': convertors.display(booleans.showHeaderTitle),
    'header-subtitle-display': convertors.display(booleans.showHeaderSubtitle),
    'search-sort-display': convertors.display(booleans.showSearchSorting),
    'member-count-display': convertors.display(booleans.showMemberCount),
    'admin-badge-display': convertors.display(booleans.showAdminBadge),
    'group-image-display': convertors.display(booleans.showGroupImage),
    'create-group-button-display': convertors.display(
      booleans.showCreateGroupButton,
    ),
    'suggested-groups-display': convertors.display(
      booleans.showSuggestedGroups,
    ),
  }),
);
