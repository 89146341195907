import { createStylesParam, StyleParamType } from '@wix/tpa-settings';

import { EButtonType } from './constants';
import {
  createBackgroundColorParam,
  createBackgroundHoverColorParam,
  createBorderColorParam,
  createBorderHoverColorParam,
  createBorderRadiusParam,
  createBorderWidthParam,
  createTextColorParam,
  createTextHoverColorParam,
} from './helpers';

const primaryButtonType = createStylesParam('buttonType', {
  type: StyleParamType.Number,
  getDefaultValue: () => EButtonType.RectangleFilled,
});

export const styles = {
  primaryButtonType,
  primaryButtonColor: createStylesParam(
    'buttonColor',
    createBackgroundColorParam(primaryButtonType),
  ),
  primaryButtonTextColor: createStylesParam(
    'buttonTextColor',
    createTextColorParam(primaryButtonType),
  ),
  primaryButtonBorderColor: createStylesParam(
    'buttonBorderColor',
    createBorderColorParam(primaryButtonType),
  ),

  primaryButtonBorderWidth: createStylesParam(
    'buttonBorderWidth',
    createBorderWidthParam(primaryButtonType),
  ),

  primaryButtonBorderRadius: createStylesParam(
    'buttonBorderRadius',
    createBorderRadiusParam(primaryButtonType),
  ),

  primaryButtonHoverColor: createStylesParam(
    'buttonHoverColor',
    createBackgroundHoverColorParam(primaryButtonType),
  ),

  primaryButtonHoverTextColor: createStylesParam(
    'buttonHoverTextColor',
    createTextHoverColorParam(primaryButtonType),
  ),

  primaryButtonHoverBorderColor: createStylesParam(
    'buttonHoverBorderColor',
    createBorderHoverColorParam(primaryButtonType),
  ),
};
