import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { COMPONENT_ID } from 'common/utils/utils';
import { useController } from 'common/context/controller';
import { selectGroupSlug, selectRequiredPricingPlanIds } from 'store/selectors';

import {
  INVITE_PAID_PLANS,
  GROUPS_APP_DEFINITION_ID,
} from '../../../../../constants';

export function usePlansRestriction(groupId: string) {
  const { t } = useTranslation();
  const { pricingPlans$ } = useController();

  const slug = useSelector(selectGroupSlug(groupId));
  const planIds = useSelector(selectRequiredPricingPlanIds(groupId));

  return { navigateToPackagePicker };

  function navigateToPackagePicker() {
    pricingPlans$.navigateToPackagePicker({
      planIds,
      biOptions: {
        referralId: slug as string,
        referralInfo: 'pp restricted group',
      },
      checkout: {
        successStatus: {
          content: {
            cta: t('groups-web.restriction.plans.status.cta'),
            message: t('groups-web.restriction.plans.status.content'),
          },
          navigation: {
            type: 'section',
            options: {
              state: slug as string,
              sectionId: COMPONENT_ID.GROUP,
              appDefinitionId: GROUPS_APP_DEFINITION_ID,
              queryParams: {
                invite: INVITE_PAID_PLANS,
              },
            },
          },
        },
      },
    });
  }
}
