import React from 'react';
import cls from 'classnames';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';
import { Spinner as TPASpinner, SpinnerTypes } from 'wix-ui-tpa/cssVars';

import classes from './Spinner.scss';

interface IProps extends TPAComponentProps {
  size?: number;
}

export function Spinner(props: IProps) {
  const { className, size, ...rest } = props;

  return (
    <TPASpinner
      diameter={size}
      type={SpinnerTypes.slim}
      className={cls(classes.root, className)}
      {...rest}
    />
  );
}

Spinner.displayName = 'wui/Spinner';
