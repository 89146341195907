import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import { Typography, type ITypographyProps } from '../Typography';

import classes from './DialogContentText.scss';

interface IDialogContentTextProps extends TPAComponentProps {
  alert?: boolean;
  children?: React.ReactNode;

  typographyProps?: ITypographyProps;

  /** shortcut for typographyProps.id */
  id?: string;
}

export function DialogContentText(props: IDialogContentTextProps) {
  const { id, className, typographyProps, ...rest } = props;
  const { isMobile } = useEnvironment();

  let text: React.ReactElement;

  if (props.children && typeof props.children === 'string') {
    text = (
      <Typography
        id={id}
        variant={isMobile ? 'p2-14' : 'p2-16'}
        {...typographyProps}
      >
        {props.children}
      </Typography>
    );
  } else {
    text = props.children as React.ReactElement;
  }

  return (
    <div
      data-hook={props['data-hook']}
      className={cls(classes.root, className, {
        [classes.alert]: props.alert,
      })}
      {...rest}
    >
      {text}
    </div>
  );
}

DialogContentText.displayName = 'wui/DialogContentText';
