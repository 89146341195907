import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { useController } from 'common/context/controller';
import { getLocaleDateString } from 'common/utils/date';

import { selectDialog, selectFuturePlans } from 'store/selectors';

import { Button } from 'wui/Button';
import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';

import { usePaidPlansBi } from './bi';
import { usePlansRestriction } from './hooks';

export const FuturePlanDialog: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const { application$ } = useController();

  const bi = usePaidPlansBi();

  const dialog = useSelector(selectDialog('futurePlanDialog'));
  const groupId = dialog.params?.groupId as string;

  const futurePlans = useSelector(selectFuturePlans(groupId));

  const { navigateToPackagePicker } = usePlansRestriction(groupId);

  if (!futurePlans.length) {
    return null;
  }

  const date = getLocaleDateString(new Date(`${futurePlans[0].startsAt}`));

  return (
    <AlertDialog
      isOpen={dialog.isOpen}
      onClose={handleClose}
      aria-labelledby="fp-dialog-title"
      aria-describedby="fp-dialog-content"
    >
      <DialogTitle
        alert
        id="fp-dialog-title"
        title={t('groups-web.restriction.plans.future.dialog.title')}
      />
      <DialogContent alert>
        <DialogContentText alert id="fp-dialog-content">
          {t('groups-web.restriction.plans.future.dialog.subtitle', {
            date,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button
          outlined
          variant="basic"
          onClick={handleClose}
          fullWidth={isMobile}
        >
          {t('groups-web.restriction.plans.future.dialog.cancel')}
        </Button>
        <Button variant="basic" onClick={handleSubmit} fullWidth={isMobile}>
          {t('groups-web.restriction.plans.future.dialog.cta')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );

  function handleSubmit() {
    bi.getAnotherPlanClick();
    navigateToPackagePicker();
  }

  function handleClose() {
    application$.closeDialog('futurePlanDialog');
  }
};

FuturePlanDialog.displayName = 'FuturePlanDialog';
