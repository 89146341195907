import { createStylesParam, StyleParamType } from '@wix/tpa-settings';

export const settings = {};

export const styles = {
  showMemberCount: createStylesParam('showMemberCount', {
    getDefaultValue: () => true,
    type: StyleParamType.Boolean,
  }),

  showGroupType: createStylesParam('showGroupType', {
    getDefaultValue: () => true,
    type: StyleParamType.Boolean,
  }),
};
