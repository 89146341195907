import * as application from './application';
import * as groupInfo from './group-info';
import * as members from './members';

export const settings = {
  ...application.settings,
  ...groupInfo.settings,
  ...members.settings,
};

export const styles = {
  ...application.styles,
  ...groupInfo.styles,
  ...members.styles,
};

export { application, members, groupInfo };
