import React from 'react';
import cls from 'classnames';

import { Paper } from '../Paper';
import { Box } from '../Box';

import type {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';

import classes from './Card.scss';

export interface ICardProps extends React.ComponentProps<typeof Paper> {}

// eslint-disable-next-line react/display-name
export const Card = React.forwardRef(
  (
    props: OverridableComponentProps<ICardProps, 'div'>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { as, className, children, ...rest } = props;

    return (
      <Paper
        as={Box}
        ref={ref}
        direction="vertical"
        padding="SP5 0"
        gap="SP5"
        className={cls(classes.root, className)}
        {...rest}
      >
        {children}
      </Paper>
    );
  },
) as OverridableComponent<ICardProps, typeof Box>;

Card.displayName = 'wui/Card';
