import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { TimeoutProps } from 'react-transition-group/Transition';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import classes from './Fade.scss';

interface IFadeProps extends Omit<TimeoutProps<HTMLElement>, 'timeout'> {
  children: React.ReactNode;
  timeout?: number;
}

export function Fade(props: IFadeProps) {
  const { children, ...rest } = props;

  return (
    <CSSTransition
      timeout={300}
      classNames={classes as CSSTransitionClassNames}
      {...rest}
    >
      {children}
    </CSSTransition>
  );
}

Fade.displayName = 'wui/Fade';
