import React from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { Box } from 'wui/Box';

import { Header } from './Header';
import { ApplicationPage } from './ApplicationPage';

export const Widget = () => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  return (
    <Box gap="SP5" direction="vertical" data-hook="groups-in-ma-widget">
      <Header />
      <ApplicationPage />
    </Box>
  );
};

Widget.displayName = 'Widget';
