import {
  createStylesParam,
  StyleParamType,
  wixColorParam,
} from '@wix/tpa-settings';

export const settings = {};

export const styles = {
  applicationBackgroundColor: createStylesParam('appBackgroundColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),
  applicationBorderWidth: createStylesParam('appBorderWidth', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  applicationBorderColor: createStylesParam('appBorderColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0.3),
  }),
};
