import {
  createSettingsParam,
  createStylesParam,
  SettingsParamType,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

export const styles = {
  titleColor: createStylesParam('titleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  titleFont: createStylesParam('titleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 28 }),
  }),
  subtitleColor: createStylesParam('subtitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  subtitleFont: createStylesParam('subtitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  }),
  tabsColor: createStylesParam('tabsColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  tabsFont: createStylesParam('tabsFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  }),
};

export const settings = {
  titleText: createSettingsParam('titleText', {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => {
      return t('groups-in-ma.settings.TextTab.header.title.placeholder');
    },
  }),
  subtitleText: createSettingsParam('subtitleText', {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => {
      return t('groups-in-ma.settings.TextTab.header.subtitle.placeholder');
    },
  }),
};
