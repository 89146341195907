import React from 'react';

import {
  CancelJoinRequestDialog,
  EventsRestrictionDialog,
  FuturePlanDialog,
  GroupQuestionsDialog,
  JoinGroupDialog,
  LeaveGroupDialog,
  PricingPlansRestrictionDialog,
  PrivateProfileDisclaimer,
} from './dialogs';

export const GroupMembershipDialogs = React.memo(() => {
  return (
    <>
      <GroupQuestionsDialog />
      <PrivateProfileDisclaimer />
      <PricingPlansRestrictionDialog />
      <FuturePlanDialog />
      <EventsRestrictionDialog />
      <JoinGroupDialog data-hook="join-group-dialog" />
      <LeaveGroupDialog data-hook="leave-group-dialog" />
      <CancelJoinRequestDialog data-hook="cancel-group-join-request-dialog" />
    </>
  );
});

GroupMembershipDialogs.displayName = 'GroupMembershipDialogs';
