import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';

import { groupsRequestJoinAGroup } from '@wix/bi-logger-groups/v2';

import {
  selectDialog,
  selectGroup,
  selectGroupPrivacyStatus,
} from 'store/selectors';
import type { IJoinRequest } from 'store/groups/types';

import { useController } from 'common/context/controller';

import { Button } from 'wui/Button';
import { AlertDialog, type IAlertDialogProps } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';

import { GroupMembershipButton } from '../../GroupMembershipButton';

export function JoinGroupDialog(props: Partial<IAlertDialogProps>) {
  const { t } = useTranslation();
  const { application$, group$ } = useController();
  const { isMobile } = useEnvironment();

  const dialog = useSelector(selectDialog('joinGroup'));
  const groupId = dialog.params?.groupId as string;
  const group = useSelector(selectGroup(groupId));
  const privacyStatus = useSelector(selectGroupPrivacyStatus(groupId));

  if (!group) {
    return null;
  }

  return (
    <AlertDialog
      {...props}
      isOpen={dialog.isOpen}
      onClose={handleClose}
      aria-labelledby="jg-dialog-title"
      aria-describedby="jg-dialog-content"
    >
      <DialogTitle
        alert
        id="jg-dialog-title"
        title={t('groups-web.join.title')}
      />
      <DialogContent alert>
        <DialogContentText alert id="jg-dialog-content">
          {t('groups-web.join.text', {
            group: group.name,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button
          outlined
          variant="basic"
          onClick={handleClose}
          fullWidth={isMobile}
          data-hook="cancel-button"
        >
          {t('groups-web.cancel')}
        </Button>
        <GroupMembershipButton
          fullWidth={isMobile}
          variant="basic"
          data-hook="confirm-button"
          groupId={groupId}
          onClick={handleSubmit}
          bi={groupsRequestJoinAGroup({
            group_id: groupId,
            origin: 'public_post_join_group_btn',
            type: (privacyStatus || '').toLowerCase(),
          })}
        />
      </DialogActions>
    </AlertDialog>
  );

  function handleSubmit() {
    group$.join(dialog.params as IJoinRequest);
  }

  function handleClose() {
    application$.closeDialog('joinGroup');
  }
}

JoinGroupDialog.displayName = 'JoinGroupDialog';
