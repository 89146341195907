import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Typography, type ITypographyProps } from '../Typography';
import { type TPAComponentProps } from '../types';

import classes from './DialogTitle.scss';

export interface IDialogTitleProps extends TPAComponentProps {
  alert?: boolean;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;

  /** shortcut for titleProps.id */
  id?: string;
  titleProps?: ITypographyProps;
  subtitleProps?: ITypographyProps;
}

export function DialogTitle(props: IDialogTitleProps) {
  const {
    id,
    className,
    alert,
    title: _,
    subtitle: __,
    titleProps,
    subtitleProps,
    ...rest
  } = props;

  const { isMobile } = useEnvironment();

  let title: React.ReactElement;
  let subtitle: React.ReactElement;

  if (props.title && typeof props.title === 'string') {
    title = (
      <Typography
        id={id}
        as="h2"
        variant={
          alert ? (isMobile ? 'h2-20' : 'h2-32') : isMobile ? 'p2-16' : 'h2-20'
        }
        {...titleProps}
      >
        {props.title}
      </Typography>
    );
  } else {
    title = props.title as React.ReactElement;
  }

  if (props.subtitle && typeof props.subtitle === 'string') {
    subtitle = (
      <Typography
        secondary
        variant={isMobile ? 'p2-14' : 'p2-16'}
        {...subtitleProps}
      >
        {props.subtitle}
      </Typography>
    );
  } else {
    subtitle = props.subtitle as React.ReactElement;
  }

  return (
    <div
      className={cls(classes.root, className, {
        [classes.alert]: alert,
        [classes.mobile]: isMobile,
      })}
      {...rest}
    >
      {title && (
        <div data-hook="title">
          {React.cloneElement(title, {
            ...title.props,
            className: cls(title.props.className, classes.title),
          })}
        </div>
      )}

      {subtitle && (
        <div data-hook="subtitle">
          {React.cloneElement(subtitle, {
            ...subtitle.props,
            className: cls(subtitle.props.className, classes.subtitle),
          })}
        </div>
      )}
    </div>
  );
}

DialogTitle.displayName = 'wui/DialogTitle';
