import React from 'react';
import cls from 'classnames';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import classes from './ButtonGroup.scss';

interface IButtonGroupProps extends TPAComponentProps {
  as?: React.ElementType;
  orientation?: 'horizontal' | 'vertical';

  children: React.ReactNode;
}

/**
 * @deprecated Use `Stack` or `Box` instead
 */
export function ButtonGroup(props: IButtonGroupProps) {
  const { orientation = 'horizontal', as: Element = 'div' } = props;

  return (
    <Element
      data-hook={props['data-hook']}
      data-orientation={orientation}
      className={cls(classes.root, props.className, {
        [classes.vertical]: orientation === 'vertical',
        [classes.horizontal]: orientation === 'horizontal',
      })}
    >
      {props.children}
    </Element>
  );
}

ButtonGroup.displayName = 'wui/ButtonGroup';
