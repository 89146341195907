import React from 'react';
import cls from 'classnames';

import {
  Image,
  ImageProps,
  ImageHoverEffectOptions,
  ImageLoadingBehaviorOptions,
  ImageResizeOptions,
} from 'wix-ui-tpa/cssVars';

import classes from './Media.scss';

interface IProps
  extends Omit<ImageProps, 'resize' | 'hoverEffect' | 'loadingBehavior'> {
  skipMeasure?: boolean;
  resize?: keyof typeof ImageResizeOptions;
  hoverEffect?: keyof typeof ImageHoverEffectOptions;
  loadingBehavior?: keyof typeof ImageLoadingBehaviorOptions;
  wired?: boolean;
}

export function Media(props: IProps) {
  const { resize, hoverEffect, loadingBehavior, className, wired, ...rest } =
    props;

  return (
    <Image
      resize={resize as ImageResizeOptions}
      hoverEffect={hoverEffect as ImageHoverEffectOptions}
      loadingBehavior={loadingBehavior as ImageLoadingBehaviorOptions}
      className={cls(classes.root, { [classes.wired]: wired }, className)}
      {...rest}
    />
  );
}

Media.displayName = 'wui/Media';

export type { ImageFocalPoint } from 'wix-ui-tpa/cssVars';
