import React from 'react';

import { Box } from '../Box';

interface ICardActionsProps extends React.ComponentProps<typeof Box> {
  children?: React.ReactNode;
  disableSideGutters?: boolean;
}

export function CardActions({
  children,
  disableSideGutters,
  ...rest
}: ICardActionsProps) {
  return (
    <Box padding={disableSideGutters ? undefined : 'SP0 SP5'} {...rest}>
      {children}
    </Box>
  );
}

CardActions.displayName = 'wui/CardActions';
