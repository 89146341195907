import React from 'react';
import cls from 'classnames';

import { Box } from '../Box';

import classes from './Skeleton.scss';

interface ISkeletonProps extends React.ComponentProps<typeof Box> {
  bw?: boolean;
  variant?: 'text' | 'circular' | 'rounded' | 'rect';
}

export function Skeleton(props: ISkeletonProps) {
  const { bw, className, variant = 'text', ...rest } = props;

  return (
    <Box
      className={cls(classes.root, className, {
        [classes.bw]: bw,
        [classes.rounded]: variant === 'rounded',
        [classes.circular]: variant === 'circular',
        [classes.text]: variant === 'text',
        [classes.rect]: variant === 'rect',
      })}
      {...rest}
    />
  );
}

Skeleton.displayName = 'wui/Skeleton';
