import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast as createToast, ToastContainer } from 'react-toastify';
import { Trans, useEnvironment } from '@wix/yoshi-flow-editor';

import { IToast, selectToasts } from 'store/application/toasts';
import { useController } from 'common/context/controller';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Typography } from 'wui/Typography';
import { Portal } from 'wui/Portal';

import { TOAST_MESSAGE } from './dataHook';

import './Toasts.global.css';
import classes from './Toasts.scss';

export function Toasts() {
  const { isMobile, isRTL, isSSR } = useEnvironment();

  const { application$ } = useController();
  const toasts = useSelector(selectToasts);

  useEffect(() => {
    if (isSSR) {
      return;
    }

    toasts.forEach((toast) => {
      const isError = toast.type === 'error';
      const timeout = isError ? 10000 : 2000;

      createToast(
        <Box
          gap="SP0"
          direction="vertical"
          onClick={isError ? () => copyDebugMessage(toast) : undefined}
        >
          <Typography
            data-hook={TOAST_MESSAGE}
            variant={isMobile ? 'p2-14' : 'p2-16'}
          >
            <Trans
              i18nKey={toast.i18nParams.i18nKey}
              values={toast.i18nParams.values}
              components={{ bold: <b /> }}
            />
          </Typography>
          <Show if={!!toast.resolvedError?.message}>
            <Typography secondary variant={isMobile ? 'p2-12' : 'p2-14'}>
              {toast.resolvedError?.message}
            </Typography>
          </Show>
          <Show if={!!toast.requestId}>
            <Typography
              secondary
              variant={isMobile ? 'p2-12' : 'p2-14'}
              data-hook="toast-request-id"
            >
              Request ID: {toast.requestId}
            </Typography>
          </Show>
        </Box>,
        {
          type: toast.type,
          toastId: toast.id,
          autoClose: toast.timeout || timeout,
          onClose: handleToastClose(toast.id),
        },
      );
    });
  }, [toasts.map((toast) => toast.id).toString(), isSSR]);

  return (
    <Portal>
      <ToastContainer
        pauseOnHover
        closeOnClick
        hideProgressBar
        theme="colored"
        rtl={isRTL}
        closeButton={false}
        draggable={isMobile}
        pauseOnFocusLoss={false}
        limit={isMobile ? 2 : 5}
        className={classes.root}
        position={isMobile ? 'bottom-center' : 'top-center'}
      />
    </Portal>
  );

  async function copyDebugMessage(toast: IToast) {
    try {
      const message = `[${toast.requestId}]: ${toast.action} - ${toast.description}`;

      await navigator.clipboard.writeText(message);
    } catch {}
  }

  function handleToastClose(toastId: string) {
    return () => {
      application$.closeToast(toastId);
    };
  }
}

Toasts.displayName = 'Toasts';
