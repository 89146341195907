import { useLayoutEffect } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

interface IViewportResizerProps {
  disabled?: boolean;
  mode?: 'none' | 'native';
  container: HTMLDivElement | null;
}

export function useViewportResizer(props: IViewportResizerProps) {
  const { container, mode = 'none', disabled = false } = props;
  const { isMobile } = useEnvironment();

  // resize container on keyboard open
  useLayoutEffect(() => {
    if (!isMobile || !visualViewport || disabled) {
      return;
    }

    return visualViewport.addEventListener('resize', function () {
      if (!container) {
        return;
      }

      switch (mode) {
        case 'none':
          break;

        case 'native':
          container.style.height = visualViewport!.height.toString() + 'px';
          break;
      }
    });
  }, [isMobile, mode, disabled]);
}
