import React from 'react';
import { useRouter, Route } from '@wix/tpa-router/react';
import {
  ErrorMonitorBoundary,
  Trans,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { ErrorState } from 'wui/ErrorState';

import { MyPosts } from '../MyPosts/loadable';
import { MyGroups } from '../MyGroups/loadable';

export function ApplicationPage() {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <ErrorMonitorBoundary
      fallback={
        <ErrorState
          wired
          title={t('groups-web.discussion.error-state.title')}
          subtitle={
            <Trans
              i18nKey={t('groups-web.discussion.error-state.owner.message')}
              components={[<span key={0} />, <br key={1} />, <span key={2} />]}
            />
          }
          actionLabel={t('groups-web.discussion.error-state.cta')}
          onRetry={handleRetry}
        />
      }
    >
      <Route state="my-groups.groups" component={MyGroups} />
      <Route state="my-groups.posts" component={MyPosts} />
    </ErrorMonitorBoundary>
  );

  function handleRetry() {
    router.reload();
  }
}

ApplicationPage.displayName = 'ApplicationPage';
