import { useState, useLayoutEffect } from 'react';

export function useDidHydrate() {
  const [isHydrated, setIsHydrated] = useState(false);

  useLayoutEffect(() => {
    setIsHydrated(true);
  }, []);

  return { isHydrated };
}
