export interface DateFormat {
  locales?: string | string[];
  options?: Intl.DateTimeFormatOptions;
  hideCurrentYear?: boolean;
}

export function getLocaleDateString(date: Date, format: DateFormat = {}) {
  const { locales, options, hideCurrentYear } = format;
  const _options = options || {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const isCurrentYear = new Date().getFullYear() === date.getFullYear();
  if (hideCurrentYear && _options && isCurrentYear) {
    _options.year = undefined;
  }
  return date.toLocaleDateString(locales || [], _options);
}
