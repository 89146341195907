import * as header from './header';

export const styles = {
  ...header.styles,
};

export const settings = {
  ...header.settings,
};

export { header };
