import React from 'react';
import cls from 'classnames';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import { Fade } from '../Fade';

import classes from './Backdrop.scss';

interface IBackdropProps extends TPAComponentProps {
  isOpen?: boolean;
  children?: React.ReactNode;
  transparent?: boolean;

  onClick?(event: React.MouseEvent): void;
}

export function Backdrop(props: IBackdropProps) {
  const { className, isOpen, onClick, transparent } = props;

  return (
    <Fade appear in={isOpen} timeout={300}>
      <div
        aria-hidden
        onClick={onClick}
        data-hook={props['data-hook']}
        data-transparent={transparent}
        className={cls(classes.root, className, {
          [classes.transparent]: transparent,
        })}
      >
        {props.children}
      </div>
    </Fade>
  );
}

Backdrop.displayName = 'wui/Backdrop';
