import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Dialog, type IDialogProps } from '../Dialog';

import classes from './InputDialog.scss';

export interface IInputDialogProps extends IDialogProps {}

export function InputDialog(props: IInputDialogProps) {
  const { className, paperProps, ...rest } = props;
  const { isMobile } = useEnvironment();

  return (
    <Dialog
      size="md"
      fullscreen={isMobile}
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
      })}
      paperProps={{
        ...paperProps,
        className: cls(classes.paper, paperProps?.className),
      }}
      {...rest}
    />
  );
}

InputDialog.displayName = 'wui/InputDialog';
