import {
  createStylesParam,
  StyleParamType,
  wixColorParam,
} from '@wix/tpa-settings';

export const styles = {
  paperBackgroundColor: createStylesParam('cardBackgroundColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),

  paperBorderColor: createStylesParam('cardBorderColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  }),

  paperBorderWidth: createStylesParam('cardBorderWidth', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),

  paperBorderRadius: createStylesParam('cardBorderCornerRadius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),

  paperShadow: createStylesParam('applyShadow', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),

  paperShadowAngle: createStylesParam('shadowAngle', {
    type: StyleParamType.Number,
    getDefaultValue: () => 180,
  }),

  paperShadowDistance: createStylesParam('shadowDistance', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),

  paperShadowBlur: createStylesParam('shadowBlur', {
    type: StyleParamType.Number,
    getDefaultValue: () => 10,
  }),

  paperShadowColor: createStylesParam('shadowColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.1),
  }),
};
