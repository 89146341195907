import { createStylesParam, StyleParamType } from '@wix/tpa-settings';

import { EButtonType } from './constants';
import {
  createBackgroundColorParam,
  createBackgroundHoverColorParam,
  createBorderColorParam,
  createBorderHoverColorParam,
  createBorderRadiusParam,
  createBorderWidthParam,
  createTextColorParam,
  createTextHoverColorParam,
} from './helpers';

const secondaryButtonType = createStylesParam('secondaryButtonType', {
  type: StyleParamType.Number,
  getDefaultValue: () => EButtonType.RectangleOutlined,
});

export const styles = {
  secondaryButtonType,

  secondaryButtonColor: createStylesParam(
    'secondaryButtonColor',
    createBackgroundColorParam(secondaryButtonType),
  ),

  secondaryButtonTextColor: createStylesParam(
    'secondaryButtonTextColor',
    createTextColorParam(secondaryButtonType),
  ),
  secondaryButtonBorderColor: createStylesParam(
    'secondaryButtonBorderColor',
    createBorderColorParam(secondaryButtonType),
  ),
  secondaryButtonBorderWidth: createStylesParam(
    'secondaryButtonBorderWidth',
    createBorderWidthParam(secondaryButtonType),
  ),
  secondaryButtonBorderRadius: createStylesParam(
    'secondaryButtonBorderRadius',
    createBorderRadiusParam(secondaryButtonType),
  ),
  secondaryButtonHoverColor: createStylesParam(
    'secondaryButtonHoverColor',
    createBackgroundHoverColorParam(secondaryButtonType),
  ),
  secondaryButtonHoverTextColor: createStylesParam(
    'secondaryButtonHoverTextColor',
    createTextHoverColorParam(secondaryButtonType),
  ),
  secondaryButtonHoverBorderColor: createStylesParam(
    'secondaryButtonHoverBorderColor',
    createBorderHoverColorParam(secondaryButtonType),
  ),
};
