import { useMemo } from 'react';

import { createRtfFormatter, useEnvironment } from '@wix/yoshi-flow-editor';

import { capitalizeFirstLetter } from '../utils/utils';

function dateDiffInDays(a: Date, b: Date): number {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  return Math.abs(a.getTime() - b.getTime()) / MS_PER_DAY;
}

export function useRelativeTimeFormatter() {
  const { language } = useEnvironment();

  // Currently Wix identifies supported languages by “macro-languages” only.
  // For example, ‘zh’ refers to Chinese, which included many subtypes/dialects based on regions(TW, HK, ...)
  // or scripts(Simplified Han, Traditional Han) which may defer in character types.
  // The default fallback for “zh” is Simplified Chinese (zh-CN, zh-Hans,…) characters
  // which is not the supported script type of Wix:
  // currently being Traditional Chinese (zh-Hant, zh-TW, ...).
  //
  // In this specific case, an additional language tag need to be specified to allow 3rd party to obtain the correct complete locale (Traditional Chinese).
  // “zh” => “zh-Hans” (Simplified Chinese) | “zh” => “zh-Hant” (Traditional Chinese)
  const locale = language === 'zh' ? 'zh-Hant' : language;

  return useMemo(() => {
    // createRtfFormatter from @wix/yoshi-flow-editor support days as max unit.
    // "666 days ago" looks strange.
    // So we try to print localized date if days ago > 31.
    return function (time: string | number | Date): string {
      const daysDiff = dateDiffInDays(new Date(time), new Date());
      const isExceeded = daysDiff > 31;

      if (isExceeded && !!Intl.DateTimeFormat) {
        const rtf = new Intl.DateTimeFormat(locale, {
          dateStyle: 'long',
        });

        return rtf.format(new Date(time));
      }

      const relativeDate = createRtfFormatter(
        locale,
        { floorSeconds: true, extraShortExpression: false },
        {
          numeric: 'auto',
          localeMatcher: 'lookup',
          style: 'long',
        },
      )(time);

      const relativeDateText =
        locale === 'fr' ? capitalizeFirstLetter(relativeDate) : relativeDate;

      return String(relativeDateText);
    };
  }, [language]);
}
