import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

import * as feed from 'settings/feed';
import * as groups from 'settings/groups';

export type ISettingsParams = {
  titleText: SettingsParamType.Text;
  subtitleText: SettingsParamType.Text;
};

const membersAreaGroupsSettings = createSettingsParams<ISettingsParams>({
  titleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => {
      return t('groups-in-ma.settings.TextTab.header.title.placeholder');
    },
  },
  subtitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => {
      return t('groups-in-ma.settings.TextTab.header.subtitle.placeholder');
    },
  },
});

export default {
  ...feed.settings,
  ...groups.settings,
  ...membersAreaGroupsSettings,
};
