import { partition } from 'lodash';
import { Event, Status, SiteUrl } from '@wix/ambassador-events-v3-event/types';

export function filterEvents(
  events: Event[],
): [upcoming: Event[], past: Event[]] {
  const [upcoming, past] = partition(events, (event) =>
    [Status.STARTED, Status.UPCOMING].includes(event.status as Status),
  );

  return [upcoming || [], past || []];
}

export function getEventPage(eventPageUrl: SiteUrl | undefined) {
  return eventPageUrl ? `${eventPageUrl.base}${eventPageUrl.path}` : undefined;
}
