import React from 'react';
import { WidgetProps, useBi, useEnvironment } from '@wix/yoshi-flow-editor';

// import { groupsBmPageLoadedSrc111Evid511 as pageLoadedEvent } from '@wix/bi-logger-groups-data/v2';

import type { IControllerProps } from 'controller/types';

import { Container } from 'wui/Container';

import { SocialGroupsApp } from 'common/components/SocialGroupsApp';

import { Widget } from './Widget';

import styles from './index.scss';

function MemebersAreaGroups(props: WidgetProps<IControllerProps>) {
  const { isMobile } = useEnvironment();
  const bi = useBi();

  // React.useEffect(() => {
  //   bi.report(
  //     pageLoadedEvent({
  //       userEntry: 'site',
  //       pageName: 'groups in members area',
  //       loadStatus: 'loaded',
  //     }),
  //   );
  // }, []);

  return (
    <SocialGroupsApp {...props}>
      <Container fluid={isMobile} className={styles.container}>
        <Widget />
      </Container>
    </SocialGroupsApp>
  );
}

MemebersAreaGroups.displayName = 'MemebersAreaGroupsWidget';

export default MemebersAreaGroups;
