import React from 'react';

interface IHideProps {
  if?: boolean;
  children: React.ReactNode;
}

export function Hide(props: IHideProps) {
  const { if: condition = true, children, ...rest } = props;

  return !condition ? (
    <>
      {React.isValidElement(children)
        ? React.cloneElement(children, rest)
        : children}
    </>
  ) : null;
}

Hide.displayName = 'wui/Hide';
