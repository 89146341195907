import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { createHttpClient, useEnvironment } from '@wix/yoshi-flow-editor';

import { selectUserToken } from 'store/selectors';

export function useHttpClient() {
  const { isSSR } = useEnvironment();
  const instance = useSelector(selectUserToken);

  return useMemo(
    () =>
      createHttpClient({
        isSSR,
        getAppToken() {
          return instance;
        },
      }),
    [instance, isSSR],
  );
}
