import React from 'react';
import cls from 'classnames';

import { Popover as PopoverTPA } from 'wix-ui-tpa/cssVars';

import { Paper, type IPaperProps } from '../Paper';

import classes from './Popover.scss';
import FocusTrap from 'focus-trap-react';

export interface IPopoverProps
  extends Omit<
    React.ComponentProps<typeof PopoverTPA>,
    'onClickOutside' | 'shown'
  > {
  isOpen?: boolean;
  content: React.ReactNode;
  children: React.ReactNode;
  paperProps?: IPaperProps;
  focusTrap?: boolean;

  onClose(): void;
}

export function Popover(props: IPopoverProps) {
  const {
    children,
    className,
    onClose,
    content,
    isOpen,
    placement = 'top',
    minWidth = 200,
    maxWidth = 320,
    paperProps,
    focusTrap = true,
    ...rest
  } = props;

  const Content = (
    <Paper
      bw
      shadow={false}
      sideBorders={false}
      topBottomBorders={false}
      {...paperProps}
    >
      {content}
    </Paper>
  );

  return (
    <PopoverTPA
      shown={isOpen}
      minWidth={minWidth}
      maxWidth={maxWidth}
      onEscPress={onClose}
      onClickOutside={onClose}
      placement={placement}
      disableClickOutsideWhenClosed
      className={cls(classes.root, className)}
      contentClassName={classes.content}
      zIndex={classes.zIndex as unknown as number}
      {...rest}
    >
      <PopoverTPA.Element>{children}</PopoverTPA.Element>
      <PopoverTPA.Content>
        {focusTrap ? <FocusTrap active={isOpen}>{Content}</FocusTrap> : Content}
      </PopoverTPA.Content>
    </PopoverTPA>
  );
}

Popover.displayName = 'wui/Popover';
