import React from 'react';
import ReactDOM from 'react-dom';

import { useDidHydrate } from 'common/hooks';

import { usePortalContainer } from './hooks';

interface IPortalProps {
  disable?: boolean;
  children: React.ReactNode;
}

export function Portal(props: IPortalProps) {
  const { isHydrated } = useDidHydrate();
  const container = usePortalContainer();

  if (props.disable) {
    return <>{props.children}</>;
  }

  if (!isHydrated) {
    return null;
  }

  return ReactDOM.createPortal(props.children, container as HTMLElement);
}

Portal.displayName = 'wui/Portal';
