import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Text, TextPriority, TextProps } from 'wix-ui-tpa/cssVars';

import type {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';

import classes from './Typography.scss';
import { classes as stClasses } from './Typography.st.css';

// see https://zeroheight.com/7sjjzhgo2/p/00b9e9-typography/t/106766
export type Variant =
  | 'h2-32'
  | 'h2-28'
  | 'h2-24'
  | 'h2-20'
  | 'h2-16'
  | 'h2-14'
  | 'p2-20'
  | 'p2-16'
  | 'p2-14'
  | 'p2-12';

export type Skin = 'error';

export interface ITypographyProps
  extends Omit<TextProps, 'typography' | 'priority' | 'tagName'> {
  variant?: Variant;
  children?: React.ReactNode;
  skin?: Skin;
  secondary?: boolean;
  noWrap?: boolean;
  align?: React.CSSProperties['textAlign'];
}

export const Typography = React.forwardRef(
  (
    props: OverridableComponentProps<ITypographyProps, 'p'>,
    ref: React.Ref<HTMLElement>,
  ) => {
    const {
      as = 'p',
      className,
      secondary,
      noWrap,
      align,
      skin,
      variant: _variant,
      ...rest
    } = props;

    const { isMobile } = useEnvironment();

    const variant = props.variant || (isMobile ? 'p2-14' : 'p2-16');

    return (
      <Text
        ref={ref}
        tagName={as}
        priority={secondary ? TextPriority.secondary : TextPriority.primary}
        className={cls(
          classes.root,
          stClasses.root,
          className,
          classes[variant],
          {
            [classes.noWrap]: noWrap,
            [classes.secondary]: secondary,
            [classes.left]: align === 'left',
            [classes.right]: align === 'right',
            [classes.center]: align === 'center',
            [classes.error]: skin === 'error',
          },
        )}
        {...rest}
      />
    );
  },
) as OverridableComponent<ITypographyProps, 'p'>;

Typography.displayName = 'wui/Typography';
