import { type CSSProperties } from 'react';

import { EAlignment } from './constants';

export function textAlign(alignment: EAlignment): CSSProperties['textAlign'] {
  switch (alignment) {
    case EAlignment.Left:
      return 'left';

    case EAlignment.Right:
      return 'right';

    case EAlignment.Center:
      return 'center';
  }
}

export function justifyContent(
  alignment: EAlignment,
): CSSProperties['justifyContent'] {
  switch (alignment) {
    case EAlignment.Left:
      return 'flex-start';

    case EAlignment.Right:
      return 'flex-end';

    case EAlignment.Center:
      return 'center';
  }
}

export function alignItems(alignment: EAlignment): CSSProperties['alignItems'] {
  switch (alignment) {
    case EAlignment.Left:
      return 'flex-start';

    case EAlignment.Right:
      return 'flex-end';

    case EAlignment.Center:
      return 'center';
  }
}

export function display(value: boolean): CSSProperties['display'] {
  return value ? undefined : 'none';
}

export function visibility(value: boolean): CSSProperties['visibility'] {
  return value ? undefined : 'hidden';
}
