import * as button from './button';
import * as paper from './paper';
import * as card from './card';
import * as textButton from './text-button';
import * as textField from './text-field';

export const styles = {
  ...button.styles,
  ...paper.styles,
  ...card.styles,
  ...textButton.styles,
  ...textField.styles,
};

export { button, paper, card, textButton, textField };
