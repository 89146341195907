import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

// Same as useEffect but does not call callback on mount
// A helper hook to do not trigger excess fetches when using persisted state
export function useDidUpdate(callback: EffectCallback, deps?: DependencyList) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      callback();
    } else {
      didMount.current = true;
    }
  }, deps);
}
