import {
  createStylesParam,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

export const styles = {
  cardTitleColor: createStylesParam('cardTitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  cardTitleFont: createStylesParam('cardTitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', { size: 16 }),
  }),

  cardSubtitleColor: createStylesParam('cardSubtitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  }),
  cardSubtitleFont: createStylesParam('cardSubtitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  }),

  cardBodyTextColor: createStylesParam('cardBodyTextColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  cardBodyTextFont: createStylesParam('cardBodyTextFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  }),
};
