import { IStyleParam, StyleParamType, wixColorParam } from '@wix/tpa-settings';

import { EButtonType } from './constants';

export function createBackgroundColorParam(
  type: IStyleParam<StyleParamType.Number>,
): IStyleParam<StyleParamType.Color> {
  return {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      switch (props.getStyleParamValue(type)) {
        case EButtonType.RectangleFilled:
        case EButtonType.RoundedFilled:
          return wixColorParam('color-8', 1)(props);

        case EButtonType.RectangleOutlined:
        case EButtonType.RoundedOutlined:
        default:
          return wixColorParam('color-8', 0)(props);
      }
    },
  };
}

export function createBackgroundHoverColorParam(
  type: IStyleParam<StyleParamType.Number>,
): IStyleParam<StyleParamType.Color> {
  return {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      switch (props.getStyleParamValue(type)) {
        case EButtonType.RectangleFilled:
        case EButtonType.RoundedFilled:
          return wixColorParam('color-8', 0.7)(props);

        case EButtonType.RectangleOutlined:
        case EButtonType.RoundedOutlined:
        default:
          return wixColorParam('color-8', 0)(props);
      }
    },
  };
}

export function createBorderColorParam(
  type: IStyleParam<StyleParamType.Number>,
): IStyleParam<StyleParamType.Color> {
  return {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      switch (props.getStyleParamValue(type)) {
        case EButtonType.RectangleFilled:
        case EButtonType.RoundedFilled:
          return wixColorParam('color-8', 0)(props);

        case EButtonType.RectangleOutlined:
        case EButtonType.RoundedOutlined:
        default:
          return wixColorParam('color-8', 1)(props);
      }
    },
  };
}

export function createBorderHoverColorParam(
  type: IStyleParam<StyleParamType.Number>,
): IStyleParam<StyleParamType.Color> {
  return {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      switch (props.getStyleParamValue(type)) {
        case EButtonType.RectangleFilled:
        case EButtonType.RoundedFilled:
          return wixColorParam('color-8', 0)(props);

        case EButtonType.RectangleOutlined:
        case EButtonType.RoundedOutlined:
        default:
          return wixColorParam('color-8', 0.7)(props);
      }
    },
  };
}

export function createTextColorParam(
  type: IStyleParam<StyleParamType.Number>,
): IStyleParam<StyleParamType.Color> {
  return {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      switch (props.getStyleParamValue(type)) {
        case EButtonType.RectangleFilled:
        case EButtonType.RoundedFilled:
          return wixColorParam('color-1')(props);

        case EButtonType.RectangleOutlined:
        case EButtonType.RoundedOutlined:
        default:
          return wixColorParam('color-8')(props);
      }
    },
  };
}

export function createTextHoverColorParam(
  type: IStyleParam<StyleParamType.Number>,
): IStyleParam<StyleParamType.Color> {
  return {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      switch (props.getStyleParamValue(type)) {
        case EButtonType.RectangleFilled:
        case EButtonType.RoundedFilled:
          return wixColorParam('color-1', 0.7)(props);

        case EButtonType.RectangleOutlined:
        case EButtonType.RoundedOutlined:
        default:
          return wixColorParam('color-8', 0.7)(props);
      }
    },
  };
}

export function createBorderWidthParam(
  type: IStyleParam<StyleParamType.Number>,
): IStyleParam<StyleParamType.Number> {
  return {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      switch (props.getStyleParamValue(type)) {
        case EButtonType.RectangleFilled:
        case EButtonType.RoundedFilled:
          return 0;

        case EButtonType.RoundedOutlined:
        case EButtonType.RectangleOutlined:
        default:
          return 1;
      }
    },
  };
}

export function createBorderRadiusParam(
  type: IStyleParam<StyleParamType.Number>,
): IStyleParam<StyleParamType.Number> {
  return {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      switch (props.getStyleParamValue(type)) {
        case EButtonType.RectangleFilled:
        case EButtonType.RectangleOutlined:
          return 0;

        case EButtonType.RoundedOutlined:
        case EButtonType.RoundedFilled:
        default:
          return 100;
      }
    },
  };
}
