import React from 'react';
import cls from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { Modal } from '../Modal';
import { Direction, Slide } from '../Slide';
import { Paper } from '../Paper';
import { IconButton } from '../IconButton';
import {
  Close as CloseIcon,
  CloseSmall as CloseIconSmall,
} from '@wix/wix-ui-icons-common/on-stage';

import classes from './Drawer.scss';

export interface IDrawerProps
  extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  bw?: boolean;
  anchor?: Direction;
  children?: React.ReactNode;
  hideCloseButton?: boolean;

  paperProps?: React.ComponentProps<typeof Paper>;

  /** shortcut for paperProps a11y props */
  'aria-labelledby'?: string;
  'aria-describedby'?: string;
}

export function Drawer(props: IDrawerProps) {
  const {
    anchor = 'bottom',
    hideCloseButton = true,
    bw = true,
    paperProps,
    'aria-labelledby': ariaLabelledby,
    'aria-describedby': ariaDescribedby,
  } = props;

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  return (
    <Modal focusTrap={!isMobile} {...props}>
      <Slide appear direction={anchor} in={props.isOpen} timeout={300}>
        <Paper
          bw={bw}
          shadow={false}
          sideBorders={false}
          topBottomBorders={false}
          role="dialog"
          aria-modal="true"
          aria-labelledby={ariaLabelledby}
          aria-describedby={ariaDescribedby}
          {...paperProps}
          data-hook="drawer-paper"
          data-anchor={anchor}
          className={cls(classes.paper, paperProps?.className, {
            [classes.mobile]: isMobile,
            [classes.left]: anchor === 'left',
            [classes.right]: anchor === 'right',
            [classes.top]: anchor === 'top',
            [classes.bottom]: anchor === 'bottom',
          })}
        >
          {!hideCloseButton && (
            <IconButton
              aria-label={t('groups-web.a11y.close')}
              className={classes.close}
              onClick={props.onClose}
              icon={isMobile ? <CloseIconSmall /> : <CloseIcon />}
              data-hook="close-icon-button"
            />
          )}

          {props.children}
        </Paper>
      </Slide>
    </Modal>
  );
}

Drawer.displayName = 'wui/Drawer';
