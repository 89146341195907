import {
  createStylesParam,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

export const styles = {
  textButtonFont: createStylesParam('textButtonFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  }),

  textButtonColor: createStylesParam('textButtonColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
};
