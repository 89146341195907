export enum COMPONENT_ID { // from dev center
  GROUP_LIST = 'groups',
  GROUP_PAGE = 'group-page',
  GROUP = 'group',
  GROUPS_AREA = 'groups_area',
}

/**
 * TODO remove this masterpiece
 *
 * checks if an object has object type not null
 * @deprecated
 * @param o
 */
export function isObject(o: any) {
  return o === Object(o);
}

export function isValidEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim());
}

export function isMobileSettings() {
  return window.Wix.Utils.getDeviceType() === 'mobile';
}

export function clamp(value: number, min: number, max: number) {
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  }

  return value;
}

export function lerp(value1: number, value2: number, amount: number) {
  amount = amount < 0 ? 0 : amount;
  amount = amount > 1 ? 1 : amount;

  return value1 + (value2 - value1) * amount;
}

export const capitalizeFirstLetter = (str: string | number | Date) =>
  typeof str === 'string' ? str?.charAt(0).toUpperCase() + str?.slice(1) : str;

export function defer<T = unknown>() {
  let reject: (reason?: any) => void;
  let resolve: (value: T) => void;

  const promise = new Promise<T>(function (_resolve, _reject) {
    resolve = _resolve;
    reject = _reject;
  });

  return {
    promise,
    reject: reject!,
    resolve: resolve!,
  };
}
