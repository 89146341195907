import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { Event as IEvent } from '@wix/ambassador-events-v3-event/types';

import { Event } from 'common/components/Event/Event';

import { InputDialog } from 'wui/InputDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { List } from 'wui/List';

interface IEventListDialogProps
  extends React.ComponentProps<typeof InputDialog> {
  events: IEvent[];
}

export function EventListDialog(props: IEventListDialogProps) {
  const { events, ...rest } = props;

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  return (
    <InputDialog aria-labelledby="el-dialog-title" {...rest}>
      <DialogTitle
        id="el-dialog-title"
        title={t('groups-web.restriction.register')}
      />
      <DialogContent>
        <List disablePadding>
          {events.map((event) => (
            <Event key={event.id} event={event} groupId="groupId" />
          ))}
        </List>
      </DialogContent>
    </InputDialog>
  );
}

EventListDialog.displayName = 'EventListDialog';
