import React, { useState } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { ActionsMenuLayout } from 'wix-ui-tpa/cssVars';
import { Drawer } from '../Drawer';
import { Popover } from '../Popover';
import { type TPAComponentProps } from '../types';

import classes from './Menu.scss';

interface IProps extends TPAComponentProps {
  isOpen?: boolean;

  element: React.ReactElement;
  children: React.ReactNode | React.ReactNode[];

  drawerProps?: Partial<React.ComponentProps<typeof Drawer>>;
  popoverProps?: Partial<React.ComponentProps<typeof Popover>>;

  onClose?(): void;
}

export function Menu(props: IProps) {
  const { element, 'data-hook': dataHook } = props;
  const { isMobile } = useEnvironment();

  const [isOpen, setIsOpen] = useState(false);

  const Element = React.cloneElement(element, {
    ...element.props,
    ...{ onClick: handleOpen },
    'data-hook': 'menu-open-element',
  });

  const children = React.Children.toArray(props.children).filter(
    (child): child is React.ReactElement => React.isValidElement(child),
  );

  const Content = children.map((child) => {
    let element: React.ReactElement;

    switch (child.type) {
      case ActionsMenuLayout.Item:
        return React.cloneElement(child, {
          ...child.props,
          onClick: handleChildClick(child.props.onClick),
        });

      case ActionsMenuLayout.Divider:
        return child;

      default:
        element = React.cloneElement(child, {
          ...child.props,
          onClick: handleChildClick(child.props.onClick),
        });

        try {
          element.type = ActionsMenuLayout.Item;
        } catch {}

        return element;
    }
  });

  if (isMobile) {
    return (
      <>
        <div data-hook={dataHook} data-is-mobile="true">
          {Element}
        </div>
        <Drawer
          bw
          anchor="bottom"
          data-hook="menu-drawer"
          isOpen={isOpen}
          onClose={handleClose}
          {...props.drawerProps}
        >
          <ActionsMenuLayout className={classes.menu} data-hook="menu-layout">
            {Content}
          </ActionsMenuLayout>
        </Drawer>
      </>
    );
  }

  return (
    <Popover
      placement="bottom-end"
      isOpen={isOpen}
      onClose={handleClose}
      data-hook={dataHook}
      content={
        <ActionsMenuLayout
          focusedIndex={0}
          className={classes.menu}
          data-hook="menu-layout"
        >
          {Content}
        </ActionsMenuLayout>
      }
      {...props.popoverProps}
    >
      {Element}
    </Popover>
  );

  function handleChildClick(onClick?: Function) {
    return function (event: React.MouseEvent<HTMLButtonElement>) {
      handleClose();
      requestAnimationFrame(() => onClick?.(event));
    };
  }

  function handleOpen() {
    setIsOpen(true);
    element.props.onClick?.();
  }

  function handleClose() {
    setIsOpen(false);
  }
}

Menu.displayName = 'wui/Menu';

export const MenuItem = ActionsMenuLayout.Item;
export const MenuDivider = ActionsMenuLayout.Divider;
